:root {
  --color-primary: #4CDCDB;
  --color-secondary: #2FBDBC;
  --color-text-primary: #323654;
  --color-text-secondary: #74799C;
  --color-placeholder: #C9CBDB;
  --color-background: #F9F9F9;
  --color-details: #FFFFFF;
  --color-button: #6A79D7;
  --color-alert: #BC2A2A;
;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body, #root {
  /* height: 100vh; */
}

body {
  color: var(--color-text-primary);
}

body,
input,
button {
  font-family: 'Roboto', sans-serif;
}

h1,
h2,
h3,
h4, 
a {
  /* color: var(--color-text-primary); */
  font-family: 'Open Sans', sans-serif;
}

h1 { 
  font-size: 48px;
}
h2 {
  font-size: 36px;
}
h3 {
  font-size: 30px;
}
h4 {
  font-size: 28px;
}

img {
  display: block;
  max-width: 100%;
}

.container {
  max-width: 1134px;
  margin: 0 auto;
}

/*  */

.flex {
  display: flex;
}

.flex-between {
  display: flex;
  justify-content: space-between;
}

.flex-column {
  display: flex;
  flex-direction: column;
}
.justify-center {
  justify-content: center;
}
.justify-between {
  justify-content: space-between;
}
.align-center {
  align-items: center;
}


/* animations */
@keyframes show-opacity {
  from {
      opacity: 0;
  }
  to {
      opacity: 1;
  }
}

/* tabela utilizada em admin e minha nuvem  */

.table-padrao {
  box-shadow: 0px 1px 2px rgba(52, 60, 88, 0.4);
  border-radius: 24px;
  background: #FFFFFF;
}

.table-padrao .labels {
  display: flex;
  background-color: #A5ACD8;
  text-align: left;
  color: #fff;
  padding: 16px 42px;
  border-radius: 24px 24px 0px 0px;
  border-bottom: 1px solid #ddd;
  font-family: 'Open Sans';
}
.table-padrao .dados {
  display: flex;
  flex-direction: column;
}
.table-padrao .dados .linha {
  display: flex;
  align-items: center;
  padding: 25px 42px;
  border-bottom: 1px solid #ddd;
}
.table-padrao .dados .linha:last-child {
  border-bottom: none;
}
.table-padrao .dados .linha .button-action {
  display: flex;
  align-items: center;
  color: var(--color-button);
  cursor: pointer;
}
.table-padrao .dados .linha .button-action svg {
  margin-right: 8px;
}

/* modais de edicao e visualizaçao das telas admin e minha nuvem */
.modal-page-admin, .modal-page-minha-nuvem {
  height: 100%;
  padding: 0 62px;
  overflow: auto;
}
.modal-page-admin header, .modal-page-minha-nuvem header{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 100px;
  margin-bottom: 56px;
  color: var(--color-text-secondary);
}

.modal-page-admin header > div > svg, .modal-page-minha-nuvem header > div > svg{
  cursor: pointer;
}

.modal-page-admin header h3, .modal-page-minha-nuvem header h3{
  font-size: 36px;
  font-family: 'Roboto';
  font-weight: 500;
  display: flex;
  align-items: center;
}
.modal-page-admin header h3 > svg, .modal-page-minha-nuvem header h3 > svg{
  width: 32px;
  height: 32px;
  margin-right: 24px;
}

.modal-page-admin form .field-group label:not(:first-child), .modal-page-minha-nuvem form .field-group label:not(:first-child){
  margin-left: 24px;
}

.modal-page-admin form .field, 
.modal-page-admin form .field-group,
.modal-page-minha-nuvem form .field, 
.modal-page-minha-nuvem form .field-group
{
  display: flex;
  align-items: center;
  margin-bottom: 24px;
}

.modal-page-admin form .field label, 
.modal-page-admin form .field-group label
.modal-page-minha-nuvem form .field label, 
.modal-page-minha-nuvem form .field-group label
{
  margin-right: 16px;
  font-size: 18px;
}

.modal-page-admin form > span, .modal-page-minha-nuvem form > span {
  display: flex;
  align-items: center;
  color: var(--color-alert);
  font-size: 18px;
  cursor: pointer;
  margin-top: 64px;
  width: fit-content;
}
.modal-page-admin form > span > svg, .modal-page-minha-nuvem form > span > svg{
  margin-right: 8px;
}

.modal-page-admin form footer, .modal-page-minha-nuvem form footer {
  background: #FFFFFF;
  box-shadow: 0px -2px 20px rgba(0, 0, 0, 0.1);
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
  padding: 15px 60px;
}
.modal-page-admin form footer main, .modal-page-minha-nuvem form footer main{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.modal-page-admin form footer main button, .modal-page-minha-nuvem form footer main button{
  width: 229px !important;
  height: 54px;
  font-size: 18px !important;
  font-family: 'Open Sans', sans-serif;
}

.modal-page-admin form footer main > span, .modal-page-minha-nuvem form footer main > span{
  display: flex;
  align-items: center;
  font-size: 24px;
  color: var(--color-text-secondary);
  cursor: pointer;
}
.modal-page-admin form footer main > span > svg, .modal-page-minha-nuvem form footer main > span > svg{
  margin-right: 8px;
}

.modal-page-admin-residente span{
  margin: 15px 0;
}


/* ;;; */
.react-select-container {
  width: 100%;
}