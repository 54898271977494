.page-blog-new-post {
  animation: show-opacity 750ms;
}

.page-blog-new-post .navbar-sec{
  background: #FFFFFF;
  box-shadow: 0px 1px 2px rgba(52, 60, 88, 0.4);
  position: absolute;
  left: 0;
  width: 100%;
  top: 84.63px;
}
.page-blog-new-post .navbar-sec nav{
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 18px;
  font-family: "Open Sans";
}
.page-blog-new-post .navbar-sec nav ul{
  display: flex;
  align-items: center;
  list-style: none;
}
.page-blog-new-post .navbar-sec nav ul li{
  padding: 29px;
  position: relative;
  margin: 0 10px;
  color: var(--color-text-secondary);
  cursor: pointer;
  transition: .2s ease-in;
}
.page-blog-new-post .navbar-sec nav ul li.selected{
  color: var(--color-text-primary);
}
.page-blog-new-post .navbar-sec nav ul li.selected::before{
  content: "";
  background: #2FBDBC;
  display: inline-block;
  position: absolute;
  left: 0;
  bottom: 0;
  height: 8px;
  width: 100%;
  background: #2FBDBC;
  border-radius: 16px 16px 0px 0px;
}

.page-blog-new-post .navbar-sec nav .mostrar-pendentes{
  color: var(--color-button);
  cursor: pointer;
  padding: 29px;
}
.page-blog-new-post .navbar-sec nav .mostrar-pendentes span{
  color: #fff;
  background: #6A79D7;
  border-radius: 16px;
  padding: 5px 10px;
  margin-left: 9px;
}
.page-blog-new-post .navbar-sec nav > span{
  padding: 29px;
}


/* main */
.page-blog-new-post> main{
  margin-top: 130px;
}
.page-blog-new-post> main header{
  display: flex;
  justify-content: space-between;
  margin-bottom: 36px;
}
.page-blog-new-post> main header p{
  font-size: 36px;
}
.page-blog-new-post> main header div{
  display: flex;
  align-items: center;
  color: var(--color-text-secondary);
  cursor: pointer;
}
.page-blog-new-post> main header div > svg{
  margin-right: 8px;
}


/* Conteudo */


.page-blog-new-post-container {
  width: 100%;
  height: auto;
  background-color: white;
  border-radius: 24px;
  filter: drop-shadow(0px 5px 14px rgba(52, 60, 88, 0.4));
  padding: 40px;
}

.page-blog-new-post-inputContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 20px;
}

.page-blog-new-post-inputContainer > label {
  margin-bottom: 15px;
  font-size: 20px;

}

.page-blog-new-post-inputContainer > input {
  background: #FFFFFF;
  border: 2px solid transparent;
  box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.15);
  border-radius: 6px;
  padding: 14px 16px;
  font-size: 15px;
  outline: none;
  width: 100%;
}

.page-blog-new-post-inputContainer > textarea {
  background: #FFFFFF;
  border: 2px solid transparent;
  box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.15);
  border-radius: 6px;
  padding: 14px 16px;
  font-size: 15px;
  outline: none;
  width: 100%;
  resize: vertical;
}

.page-blog-new-post-inputContainer-image {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 400px;
  width: 100%;
  border-width: 2px;
  border-radius: 2px;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border .24s ease-in-out;
  position: relative;
  margin-bottom: 15px;
}

.page-blog-new-post-inputContainer-image > input{
  display: none;  
}

